import React, { Component, Fragment } from "react";
import Validate from "./utility/LoginFormValidation";
import FormErrors from "./utility/FormErrors";
import { Auth } from "aws-amplify";
import Modal from "react-modal";
import axios from "axios";
import moment from "moment";
import Notification from "./Notifications.js";
import NProgress from 'nprogress'; 
const config = require("../config.json");

class Home extends Component {
  state = {
    username: "",
    password: "",
    group: "Studio",
    modalState: false,
    name: "",
    number: "",
    email: "",
    showForm: true,
    referType: "Studio",
    fullname: "",
    errors: {
      cognito: null,
      blankfield: false,
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  loginNotifcation = async () => {
    // add call to AWS API Gateway update product endpoint here
if (this.props.location.state !== undefined) {
  this.props.notification("Account activated!", this.props.location.state.loginData);
  this.props.location.state = undefined;
} 
  };

  contactHandleSubmit = async (event) => {
    event.preventDefault();
    // add call to AWS API Gateway update product endpoint here
    try {
      const params = {
        name: this.state.name,
        number: this.state.number,
        Id: this.state.referType + moment().format("x"),
        email: this.state.email,
        referType: this.state.referType,
        fullname: this.state.fullname,
      };

      const res = await axios.post(`${config.api.invokeUrl}/referrals`, params);
      this.setState({
        ...this.state,
        name: "",
        number: "",
        email: "",
        fullname: "",
        modalState: false,
        showForm: true,
      })
      this.props.notification("Referral Submitted", res.data);
    } catch (err) {
      console.log("unable to create referral" + err);
    }
  };

  handleSubmit = async (event) => {
    NProgress.start();
    event.preventDefault();
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    } else {
      // AWS Cognito integration here
      try {
        console.log("test");
        var group = this.state.group;
        var region = config.region.region;
        var APP_CLIENT_ID = "";
        var userPoolId = "";

        switch (group) {
          case "Studio":
            APP_CLIENT_ID = config.studiocognito.APP_CLIENT_ID;
            userPoolId = config.studiocognito.userPoolId;
            break;
          case "School":
            APP_CLIENT_ID = config.teachercognito.APP_CLIENT_ID;
            userPoolId = config.teachercognito.userPoolId;
            break;
          case "Parent":
            APP_CLIENT_ID = config.parentcognito.APP_CLIENT_ID;
            userPoolId = config.parentcognito.userPoolId;
            break;
          case "Admin":
            APP_CLIENT_ID = config.admincognito.APP_CLIENT_ID;
            userPoolId = config.admincognito.userPoolId;
            break;
        }

        Auth.configure({
          Auth: {
            mandatorySignIn: true,
            region: region,
            userPoolId: userPoolId,
            userPoolWebClientId: APP_CLIENT_ID,
          },
        });
        const user = await Auth.signIn(
          this.state.username,
          this.state.password
        );
        localStorage.setItem("amplify-user-pool-id", userPoolId);
        localStorage.setItem("amplify-user-app-id", APP_CLIENT_ID);
        this.props.auth.setAuthStatus(true);
        this.props.auth.setUser(user);
        const session = await Auth.currentSession();
        console.log(session);
        const user2 = await Auth.currentAuthenticatedUser({bypassCache: true})
        .then(user2 => this.props.auth.setUser(user2))
        switch (group) {
          case "Studio":
            this.props.history.push("/admin");
            break;
          case "School":
            this.props.history.push("/schoolportal");

            break;
          case "Parent":
            this.props.history.push("/StudioListing");

            break;
          case "Admin":
              this.props.history.push("/AdminPage");

            break;
        }
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error}) : (err = error);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err,
          },
        });
      }
      NProgress.done();
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };


  componentDidMount = () => {
    this.loginNotifcation();
  };

  render() {
    return (
      <section className="container loginBackground">
         <Fragment>
        <div class="modal">
          <Modal
            isOpen={this.state.modalState}
            onRequestClose={() =>
              this.setState({
                ...this.state,
                modalState: false,
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Contact Us</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      modalState: false,
                      showForm: true,
                    })
                  }
                ></button>
              </header>
              <form onSubmit={(event) => this.contactHandleSubmit(event)}>
                <div className="modal-card-body">
                  <p>Are you a school or studio?</p>
                  <div class="select">
                    <select
                      value={this.state.referType}
                      onChange={this.onInputChange}
                      id="referType"
                      required
                    >
                      <option selected disabled>
                        Choose a group
                      </option>
                      <option key="1" value="Studio" name="Studio">
                        Studio
                      </option>
                      <option key="2" value="School" name="School">
                        School
                      </option>
                    </select>
                  </div>

                  <p>Contact number:</p>

                  <input
                    className="input"
                    type="text"
                    id="name"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a contact number"
                    value={this.state.name}
                    onChange={this.onInputChange}
                    required
                  />

                  <p>school or studio name:</p>

                  <input
                    className="input"
                    type="text"
                    id="number"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a school or studio name"
                    value={this.state.number}
                    onChange={this.onInputChange}
                    required
                  />

                  <p>Contact e-mail:</p>

                  <input
                    className="input"
                    type="text"
                    id="email"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a contact email"
                    value={this.state.email}
                    onChange={this.onInputChange}
                    required
                  />

                  <p>Full name:</p>

                  <input
                    className="input"
                    type="text"
                    id="fullname"
                    aria-describedby="userNameHelp"
                    placeholder="Enter your full name"
                    value={this.state.fullname}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
                <footer class="modal-card-foot">
                  <button
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        modalState: false,
                        showForm: true,
                      })
                    }
                    class="button"
                  >
                    {" "}
                    close{" "}
                  </button>
                  <button class="button is-dark"> submit </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>
        </Fragment>
        {this.state.showForm && (
        <div class="hero-body ">
          <div class="container ">
          <div>
			  <Notification
			  />
		  </div>
            <div class="columns is-centered">
              <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                <img src="rap_therapy_logo.png" width="325px" />
                <form class="box" onSubmit={this.handleSubmit}>
                  <FormErrors formerrors={this.state.errors} />
                  <div class="field">
                    <label for="" class="label">
                      Username
                    </label>
                    <div class="control has-icons-left">
                      <input
                        class="input"
                        required
                        id="username"
                        aria-describedby="usernameHelp"
                        value={this.state.username}
                        onChange={this.onInputChange}
                      />

                      <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                  </div>
                  <div class="field">
                    <label for="" class="label">
                      Password
                    </label>
                    <div class="control has-icons-left">
                      <input
                        type="password"
                        placeholder="*******"
                        class="input"
                        id="password"
                        value={this.state.password}
                        onChange={this.onInputChange}
                      />
                      <span class="icon is-small is-left">
                        <i class="fa fa-lock"></i>
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label for="" class="label">
                      Group
                    </label>
                    <p className="control">
                      <div class="select">
                        <select
                          name="group"
                          id="group"
                          value={this.state.group}
                          onChange={this.onInputChange}
                        >
                          <option selected disabled>
                            Choose a group
                          </option>
                          <option value="Studio">Studio</option>
                          <option value="School">School</option>
                          <option value="Parent">Parent</option>
                          <option value="Admin">Admin</option>
                        </select>
                      </div>
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      <a href="/forgotpassword">Forgot password?</a>
                    </p>
                  </div>
                  <div class="field">
                    <button
                      type="button"
                      class="button is-dark"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          modalState: true,
                          showForm: false,
                        })
                      }
                    >
                      Contact Us
                    </button>
                    <button class="button is-dark">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        )}
      </section>
    );
  }
}
export default Home;
