import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from 'moment';
const config = require('../../config.json');

class SchoolRegistry extends Component {
  state = {
    schoolname: "",
    username: "",
    email: "",
    token: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // add call to AWS API Gateway update product endpoint here
    try{
      const params = {
        "schoolname" : this.state.schoolname,
        "username": this.state.username,
        "email": this.state.email,
        "group": "Teacher",
        "id": this.state.schoolname + moment().format("x")
      }

      const headers = {
        headers: { 'Authorization' : this.state.token}
      }

    await axios.post(`${config.api.invokeUrl}/schools`, params, headers);
    }catch(err) {
      console.log('unable to register user' + err)
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidMount = () => {
   }

  render() {
    return (
        <section>
          {this.props.auth.isAuthenticated && (
          <div className="container">
            <h1>School On-boarding</h1>
            <form onSubmit={event => this.handleSubmit(event)}>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="schoolname"
                    aria-describedby="userNameHelp"
                    placeholder="Enter the name of the school"
                    value={this.state.schoolname}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="username"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a username for the school"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left has-icons-right">
                  <input 
                    className="input" 
                    type="email"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter school e-mail"
                    value={this.state.email}
                    onChange={this.onInputChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <button className="button is-success">
                    Register
                  </button>
                </p>
              </div>
            </form>
          </div>
          )}
          {!this.props.auth.isAuthenticated && (
            <p>You are not authorised to access this page</p>
          )}
        </section>
    );
  }
}

export default SchoolRegistry;