function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  const phoneNum = state.studiophoneNumber.replace(/[^\d]/g, '');
  const trimedStudioUsername = /\s/g.test(state.studiousername);
  const postcodeRegex = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i;
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }

  const postcodeMatch = state.studiopostcode.match(postcodeRegex);

  if (state.hasOwnProperty("studiousername") && trimedStudioUsername == true) {
    document.getElementById("studiousername").classList.add("is-danger");
    return { usernamespaces: true };
  }

  if (state.hasOwnProperty("studioname") && state.studioname === "") {
    document.getElementById("studioname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiousername") && state.studiousername === "") {
    document.getElementById("studiousername").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studioemail") && state.studioemail === "") {
    document.getElementById("studioemail").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiofirstName") && state.studiofirstName === "") {
    document.getElementById("studiofirstName").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiolastName") && state.studiolastName === "") {
    document.getElementById("studiolastName").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiopostcode") && postcodeMatch == null) {
    document.getElementById("studiopostcode").classList.add("is-danger");
    return { postcode: true };
  }
  if (state.hasOwnProperty("studiopostcode") && state.studiopostcode === "") {
    document.getElementById("studiopostcode").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studioaddress") && state.studioaddress === "") {
    document.getElementById("studioaddress").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studioborough") && state.studioborough === "") {
    document.getElementById("studioborough").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiotown") && state.studiotown === "") {
    document.getElementById("studiotown").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiophoneNumber") && phoneNum === "") {
    document.getElementById("studiophoneNumber").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studiophoneNumber") && phoneNum.length < 11 || phoneNum.length  > 11) {
    document.getElementById("studiophoneNumber").classList.add("is-danger");
    return { phonenumber: true };
  }
  if (state.hasOwnProperty("dbsclearance") && state.dbsclearance === "") {
    document.getElementById("dbsclearance").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("dbsclearance") && state.dbsclearance == false) {
    document.getElementById("dbsclearance").classList.add("is-danger");
    return { dbscleared: true };
  }
  if (state.hasOwnProperty("safeguarding") && state.safeguarding === "") {
    document.getElementById("safeguarding").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("safeguarding") && state.safeguarding == false) {
    document.getElementById("safeguarding").classList.add("is-danger");
    return { safeguarded: true };
  }
  

  return;
}

export default validateForm;