import React, { Component, Fragment } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Modal from "react-modal";
import moment from "moment";

export default class StudioBookingCalendar extends Component {
  state = {
  booking: {
    StudioBookings: [],
  },
    modalState: false,
    fullCalendar: true,
    BookingID: "",
    updatedBookingDate: "",
    updatedBookingTime: "",
    studentname: "",
    studiousername: ""
  };

  handleDateClick = async (id, date, time, studio) => {
    this.setState({
      ...this.state,
      fullCalendar: false,
      modalState: true,
      updatedBookingDate: moment(date).format("YYYY-MM-DD"),
      updatedBookingTime: time,
      BookingID: id,
      studiousername: studio,
    });
  };

  createDates = async () => {
    this.setState({
      booking: {
      StudioBookings: this.props.bookingDates }
    });
  };

  handleEditSave = event => {
    event.preventDefault();
    this.props.handleSubmit(this.state.updatedBookingDate, this.state.updatedBookingTime, this.state.studentname, this.state.studiousername);
    this.setState({...this.state,modalState: false, fullCalendar: true })
  }

  handleDelete = event => {
    event.preventDefault();
    this.setState({...this.state,modalState: false, fullCalendar: true })
  }

  componentDidMount = () => {
    this.createDates();
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.bookingDates !== this.props.bookingDates) {
      this.createDates(this.props.bookingDates);
    }
  }

  render() {
    return (
      <Fragment>
        <div class="modal">
          <Modal
            isOpen={this.state.modalState}
            onRequestClose={() =>
              this.setState({
                studio: { ...this.state, modalState: false },
                calendarDates: [],
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Studio Booking Form</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      modalState: false,
                      fullCalendar: true,
                    })
                  }
                ></button>
              </header>
              <form onSubmit={(event) => this.handleEditSave(event)}>
                <div className="modal-card-body">
                <div class="select">
                <select 
                      value={this.state.studentname}
                      onChange={this.onInputChange}
                      id="studentname"
                      required
                    >
                      <option disabled selected value="">Select a student</option>
                      {this.props.students.map((students) => (
                        <React.Fragment>
                          <option
                            key={students.StudentID}
                            value={students.studentfirstname + " " + students.studentlastname}
                            name="student"
                          >
                            {students.studentfirstname + " " + students.studentlastname}
                          </option>
                        </React.Fragment>
                      ))}
                    </select>
                    </div>
                  <p>Studio session on: {this.state.updatedBookingDate} At: {this.state.updatedBookingTime}</p>

                </div>

                <footer class="modal-card-foot">
                  <button
                    onClick={(event) => this.handleDelete(event)}
                    class="button"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button class="button is-dark"> Book </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>

        {this.state.fullCalendar && (
          <FullCalendar
            plugins={[dayGridPlugin]}
            contentHeight={550}
            eventClick={(args) => this.handleDateClick(args.event.id, args.event.start, args.event.title, args.event.extendedProps.studio)}
            events={this.state.booking.StudioBookings}
          />
        )}
      </Fragment>
    );
  }
}
