import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import moment from "moment";

Modal.setAppElement("#root");

export default class Studios extends Component {
  state = {
    studio: {
      loading: this.props.loading,
      bookingDate: moment().format("YYYY-MM-DD"),
      bookingTime: moment().format("LT"),
      studentname: "",
      modalState: false,
      studiousername: "",
    },
    studios: this.props.studios,
    calendarDates: [],
    studentList: [],
  };

  updateBookingDates = (username) => {
    this.props.updateBookings(username);
  };

  componentDidMount = () => {};

  render() {
    return (
      <Fragment>
        {!this.props.loading && (
          <div>
            {this.props.studios.map((studio) => (
              <React.Fragment>
                <div className="pageBorder">
                  <h2 class="title is-4">
                    <a
                      className="studioLink"
                      onClick={() =>
                        this.updateBookingDates(studio.username)
                      }
                    >
                      {studio.studioname}{" "}
                    </a>
                  </h2>
                  <h3 class="title is-5">{studio.studiopostcode}, {studio.studioaddress}, {studio.studioborough}, {studio.studiotown}</h3>
                  <br></br>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </Fragment>
    );
  }
}
