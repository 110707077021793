import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import { Auth } from "aws-amplify";
const config = require('../../config.json');

class ParentRegistry extends Component {
  state = {
    parentname: "",
    username: "",
    email: "",
    token: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  AuthCreds = async event => {

    const {user} = this.props.auth.user

   await Auth.currentSession(user).then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let idToken = res.getIdToken()
    this.setState( {"token": idToken.jwtToken});
  })
} 


  handleSubmit = async (event) => {
    event.preventDefault();
    // add call to AWS API Gateway update product endpoint here
    try{
      const params = {
        "parentname" : this.state.parentname,
        "username": this.state.username,
        "email": this.state.email,
        "group": "Parent",
        "id": this.state.parentname + moment().format("x")
      }
  
      const headers = {
        headers: { 'Authorization' : this.state.token}
      }

    await axios.post(`${config.api.invokeUrl}/parents`, params, headers);
    }catch(err) {
      console.log('unable to register user' + err)
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidMount = () => {
    this.AuthCreds();
   }

  render() {
    return (
        <section className="section auth">
          {this.props.auth.isAuthenticated && (
          <div className="container">
            <h1>Parent On-boarding</h1>
            <form onSubmit={event => this.handleSubmit(event)}>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="parentname"
                    aria-describedby="userNameHelp"
                    placeholder="Enter the name of the parent"
                    value={this.state.parentname}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="username"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a username for the parent"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left has-icons-right">
                  <input 
                    className="input" 
                    type="email"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter parent e-mail"
                    value={this.state.email}
                    onChange={this.onInputChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <button className="button is-success">
                    Refer
                  </button>
                </p>
              </div>
            </form>
          </div>
          )}
          {!this.props.auth.isAuthenticated && (
            <p>You are not authorised to access this page</p>
          )}
        </section>
    );
  }
}

export default ParentRegistry;