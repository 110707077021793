function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  const phoneNum = state.phoneNumber.replace(/[^\d]/g, '');
  const trimedUsername = /\s/g.test(state.username);
  const postcodeRegex = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i;
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }

  const postcodeMatch = state.postcode.match(postcodeRegex);
  if (state.hasOwnProperty("username") && trimedUsername == true) {
    document.getElementById("username").classList.add("is-danger");
    return { usernamespaces: true };
  }
  if (state.hasOwnProperty("name") && state.name === "") {
    document.getElementById("name").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("schoolname") && state.schoolname === "") {
    document.getElementById("schoolname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("username") && state.username === "") {
    document.getElementById("username").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("email") && state.email === "") {
    document.getElementById("email").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("firstName") && state.firstName === "") {
    document.getElementById("firstName").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("lastName") && state.lastName === "") {
    document.getElementById("lastName").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("postcode") && state.postcode === "") {
    document.getElementById("postcode").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("postcode") && postcodeMatch == null) {
    document.getElementById("postcode").classList.add("is-danger");
    return { postcode: true };
  }
  if (state.hasOwnProperty("address") && state.address === "") {
    document.getElementById("address").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("borough") && state.borough === "") {
    document.getElementById("borough").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("town") && state.town === "") {
    document.getElementById("town").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("phoneNumber") && phoneNum === "") {
    document.getElementById("phoneNumber").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("phoneNumber") && phoneNum.length < 11 || phoneNum.length  > 11) {
    document.getElementById("phoneNumber").classList.add("is-danger");
    return { phonenumber: true };
  }
  if (state.hasOwnProperty("schoolType") && state.schoolType === "") {
    document.getElementById("schoolType").classList.add("is-danger");
    return { blankfield: true };
  }

  return;
}

export default validateForm;