import React, { Component } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
const config = require('../../config.json');

class StudioRegistry extends Component {
  state = {
    studioname: "",
    username: "",
    email: "",
    token: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  AuthCreds = async event => {

    const {user} = this.props.auth.user

   await Auth.currentSession(user).then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let idToken = res.getIdToken()
    this.setState( {"token": idToken.jwtToken});
  })
}


  handleSubmit = async (event) => {
    event.preventDefault();
    // add call to AWS API Gateway update product endpoint here
    try{
      const params = {  
        "studioname" : this.state.studioname,
        "username": this.state.username,
        "email": this.state.email,
        "group": "Studio",
      }

      const headers = {
        headers: { 'Authorization' : this.state.token}
      }
      await axios.post(`${config.api.invokeUrl}/studios`, params, headers);
      this.setState( {state:{ "studioname": "", "username": "", "email": ""}});
    }catch(err) {
      console.log('unable to register user' + err)
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidMount = () => {
   this.AuthCreds();
  }

  render() {
    return (
        <section className="section auth">
          {this.props.auth.isAuthenticated && (
          <div className="container">
            <h1>Studio On-boarding</h1>
            <form onSubmit={event => this.handleSubmit(event)}>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="studioname"
                    aria-describedby="userNameHelp"
                    placeholder="Enter the name of the studio"
                    value={this.state.studioname}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <input 
                    className="input" 
                    type="text"
                    id="username"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a username for the studio"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left has-icons-right">
                  <input 
                    className="input" 
                    type="email"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter studio e-mail"
                    value={this.state.email}
                    onChange={this.onInputChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <button className="button is-success">
                    Register
                  </button>
                </p>
              </div>
            </form>
          </div>
          )}
          {!this.props.auth.isAuthenticated && (
            <p>You are not authorised to access this page</p>
          )}
        </section>
    )
  }     
}

export default StudioRegistry;