import React, { Component, Fragment} from 'react';

export default class Pagination extends Component {

  state = {
    pageNumbers: [],
    lastPage: "",
    currentPage: 1
  }

  PaginationBuilder = async () => {
    const pageNumbers = [];
    const totalPosts = this.props.totalPosts;
    const postPerPage = this.props.postPerPage;
    console.log(this.props);
    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
      pageNumbers.push(i);
    }
    this.setState({pageNumbers: pageNumbers})
    this.setState({lastPage: pageNumbers.length - 1})
  }


  componentDidMount = () => {
    this.PaginationBuilder(this.props.totalPosts);
  }

  componentDidUpdate(prevProps){
    if (prevProps.totalPosts !== this.props.totalPosts) {
      this.PaginationBuilder(this.props.totalPosts)
      }
    }

    handlePageChange = (number, event) => {
      event.preventDefault();
      this.props.paginate(number, event);
      this.setState({currentPage: number})
      window.scrollTo(0, 0)
    }

  render() {
    return (
      
            <Fragment>
                    {!this.props.loading && (
                      <nav class="pagination is-centered" role="navigation" aria-label="pagination"> 
                        <ul class="pagination-list">
                        <li key="First" aria-label="Goto page 2">
                              <a class="pagination-link" onClick={event => this.handlePageChange(this.state.pageNumbers[0], event)} href="!#">
                                First
                              </a>
                          </li>
                          {this.state.pageNumbers.map(number => (
                            <li key={number} aria-label="Goto page 2">
                              <a class={this.state.currentPage === number ? 'pagination-link is-current ' : 'pagination-link'}  onClick={event => this.handlePageChange(number, event)} href="!#">
                                {number}
                              </a>
                            </li>
                          ))}
                          <li key="Last" aria-label="Goto page 2">
                              <a class="pagination-link" onClick={event => this.handlePageChange(this.state.pageNumbers[this.state.lastPage], event)} href="!#">
                                Last
                              </a>
                          </li>
                        </ul>
                      </nav>
                      )}
          </Fragment>        
    )
  }
}