import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import axios from "axios";
import moment from "moment";
import Validate from "./utility/ContactFormValidation";
import FormErrors from "./utility/ContactFormErrors";
import { Auth } from "aws-amplify";
const config = require("../config.json");

export default class Navbar extends Component {
  state = {
    modalState: false,
    name: "",
    number: "",
    email: "",
    referType: "Studio",
    fullname: "",
    errors: {
      cognito: null,
      blankfield: false
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };


  handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      localStorage.removeItem("amplify-user-pool-id");
      localStorage.removeItem("amplify-user-app-id");
      window.location = "/";
    } catch (error) {
      console.log(error.message);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    };
    // add call to AWS API Gateway update product endpoint here
    try{
      const params = {
        name: this.state.name,
        number: this.state.number,
        Id: this.state.referType + moment().format("x"),
        email: this.state.email,
        referType: this.state.referType,
        fullname: this.state.fullname
      };

    console.log(event);
    await axios.post(`${config.api.invokeUrl}/referrals`, params);
    }catch(err) {
      console.log('unable to book studio' + err)
    }
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  renderSwitch() {

    const group = this.props.auth.user.attributes['custom:cognitoGroup'];
    switch(group) {
      case 'studio':
        return 'studio';
      case 'admin':
        return 'admin';
      case 'school':
        return 'school';
      case 'parent':
        return 'parent';
    }
  }

  componentDidMount = () => {
     this.renderSwitch();
  }

  render() {
    return (
      <Fragment>
        <div class="modal">
          <Modal
            isOpen={this.state.modalState}
            onRequestClose={() =>
              this.setState({
                ...this.state,
                modalState: false,
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Contact Us</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      modalState: false,
                    })
                  }
                ></button>
              </header>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <div className="modal-card-body">
                  <FormErrors formerrors={this.state.errors} />
                  <p>Are you a school or studio?</p>
                  <div class="select">
                  <select
                    value={this.state.referType}
                    onChange={this.onInputChange}
                    id="referType"
                  >
                    <option selected disabled>Choose a group</option>
                    <option key="1" value="Studio" name="Studio">
                      Studio
                    </option>
                    <option key="2" value="School" name="School">
                      School
                    </option>
                  </select>
                  </div>

                  <p>Contact number:</p>

                  <input
                    className="input"
                    type="text"
                    id="name"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a contact number"
                    value={this.state.name}
                    onChange={this.onInputChange}
                  /> 

                  <p>school or studio name:</p>

                  <input
                    className="input"
                    type="text"
                    id="number"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a school or studio name"
                    value={this.state.number}
                    onChange={this.onInputChange}
                  />

                  <p>Contact e-mail:</p>

                  <input
                    className="input"
                    type="text"
                    id="email"
                    aria-describedby="userNameHelp"
                    placeholder="Enter a contact email"
                    value={this.state.email}
                    onChange={this.onInputChange}
                  />

                  <p>Full name:</p>

                  <input
                    className="input"
                    type="text"
                    id="fullname"
                    aria-describedby="userNameHelp"
                    placeholder="Enter your full name"
                    value={this.state.fullname}
                    onChange={this.onInputChange}
                  />
                </div>
                <footer class="modal-card-foot">
                  <button
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        modalState: false,
                      })
                    }
                    class="button"
                  >
                    {" "}
                    close{" "}
                  </button>
                  <button class="button is-dark"> submit </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
          {this.renderSwitch() == "parent" && (
            <a className="navbar-item-img-max-height" href="/StudioListing">
              <img src="rap_therapy_logo.png" width="100" height="40"></img>
            </a>
             )}
          {this.renderSwitch() == "admin" && (
            <a className="navbar-item-img-max-height" href="/AdminPage">
              <img src="rap_therapy_logo.png" width="100" height="40"></img>
            </a>
             )}
          {this.renderSwitch() == "studio" && (
            <a className="navbar-item-img-max-height" href="/admin">
              <img src="rap_therapy_logo.png" width="100" height="40"></img>
            </a>
             )}
          {this.renderSwitch() == "school" && (
            <a className="navbar-item-img-max-height" href="/schoolportal">
              <img src="rap_therapy_logo.png" width="100" height="40"></img>
            </a>
             )}
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              {this.renderSwitch() == "parent" || this.renderSwitch() == "school" && (
              <a href="/StudioListing " className="navbar-item">
                Studio Sessions
              </a>
            )}
              {this.renderSwitch() == "parent" && (
              <a href="/StudioListing " className="navbar-item">
                Studio Sessions
              </a>
            )}
            {this.renderSwitch() == "school" && (
              <a href="/schoolportal" className="navbar-item">
                School Portal
              </a>
            )}
              {this.renderSwitch() == "studio" && (
              <a href="/admin" className="navbar-item">
                Studio Portal
              </a>
              )}
            {this.renderSwitch() == "admin" && (    
              <a href="/AdminPage" className="navbar-item">
                Admin Portal
              </a>
            )}
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <div>

                  {this.props.auth.isAuthenticated && (
                    <p>Hello {this.props.auth.user.username}</p>
                  )}
                    {!this.props.auth.isAuthenticated && (
                      <a href="/" className="button is-light">
                        Log in
                      </a>
                    )}
                  </div>
                  {this.props.auth.isAuthenticated && (
                    <a
                      href="/"
                      onClick={this.handleLogOut}
                      className="button is-light"
                    >
                      Log out
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}
