function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  const phoneNum = state.phoneNumber.replace(/[^\d]/g, '');
  const trimedUsername = /\s/g.test(state.username);
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }

  if (state.hasOwnProperty("username") && trimedUsername == true) {
    document.getElementById("username").classList.add("is-danger");
    return { usernamespaces: true };
  }
  
  if (state.hasOwnProperty("parentname") && state.parentname === "") {
    document.getElementById("parentname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("username") && state.username === "") {
    document.getElementById("username").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("email") && state.email === "") {
    document.getElementById("email").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("borough") && state.borough === "") {
    document.getElementById("borough").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("town") && state.town === "") {
    document.getElementById("town").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("phoneNumber") && phoneNum === "") {
    document.getElementById("phoneNumber").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("phoneNumber") && phoneNum.length < 11 || phoneNum.length  > 11) {
    document.getElementById("phoneNumber").classList.add("is-danger");
    return { phonenumber: true };
  }

  return;
}

export default validateForm;