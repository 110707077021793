import React from 'react';
import "../App.css";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';


function App () {
    return (
        <div className="container">
            <ReactNotification/>


        </div>
    )
}



export default App;
