function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }
  if (state.hasOwnProperty("studentfirstname") && state.studentfirstname === "") {
    document.getElementById("studentfirstname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studentlastname") && state.studentlastname === "") {
    document.getElementById("studentlastname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("studentparentemail") && state.studentparentemail === "") {
    document.getElementById("studentparentemail").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("day") && state.day === "" || state.day == "Day") {
    document.getElementById("day").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("month") && state.month === "" || state.month == "Month") {
    document.getElementById("month").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("year") && state.year === "" || state.year == "Year") {
    document.getElementById("year").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("SEMHotherChoice") && state.SEMHotherChoice == true && state.SEMHother === "") {
    document.getElementById("SEMHotherChoice").classList.add("is-danger");
    return { semhother: true };
  }
  if (state.hasOwnProperty("LearningNeedsOtherChoice") && state.LearningNeedsOtherChoice == true && state.LearningNeedsOther ==="") {
    document.getElementById("LearningNeedsOtherChoice").classList.add("is-danger");
    return { learningneedsother: true };
  }
  if (state.hasOwnProperty("medicalNeeds") && state.medicalNeeds == "Yes" && state.medicalNeedsOthers ==="") {
    document.getElementById("medicalNeeds").classList.add("is-danger");
    return { medicalneedsothers: true };
  }

  return;
}

export default validateForm;