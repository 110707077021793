import React, { Component } from 'react';
import Validate from "../utility/FormValidation";
import axios from "axios";
import Notification from "../Notifications.js";
const config = require('../../config.json');

class ForgotPassword extends Component {
  state = {
    group: "",
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    try {
      const params = {
        email: this.state.email,
        group: this.state.group,
      };

      const headers = {
        headers: { "Content-Type":"application/json" },
      };

      const res = await axios.post(`${config.api.invokeUrl}/passwordreset`, params, headers);
      this.props.history.push('/forgotpasswordverification');
    }catch(res) {
      console.log("test:" + res);
     this.props.notification("Unable to reset password", res.response.data);
      
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <section className="section auth">
                  <div>
			  <Notification
			  />
		  </div>
        <div className="container">
          <h1>Forgot your password?</h1>
          <p>
            Please enter the email address associated with your account and we'll
            email you a password reset link.
          </p>
          <form onSubmit={this.forgotPasswordHandler}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  className="input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
              <div class="select">
              <select name="group" id="group" value={this.state.group} onChange={this.onInputChange} required>
              <option disabled selected value="">Choose a group</option>
                <option value="Studio">Studio</option>
                <option value="School">School</option>
                <option value="Parent">Parent</option>
                <option value="Admin">Admin</option>
                </select>
                </div>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-dark">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;