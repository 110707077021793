import React, { Component, Fragment } from "react";
import axios from "axios";
import '../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { BarLoader } from "react-spinners";
import { extendMoment } from "moment-range";
import { Auth } from "aws-amplify";
import Modal from "react-modal";
import EventCalendar from "./EventCalendar.js";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Notification from "./Notifications.js";
import Typography from "@material-ui/core/Typography";
import NProgress from 'nprogress'; 
const config = require("../config.json");
const Moment = extendMoment(moment);

export default class ProductAdmin extends Component {
  state = {
    newproduct: {
      bookingDate: "",
      bookingTime: "",
      BookingID: "",
    },
    products: [],
    studioBookings: [],
    token: "",
    tabState: {
      slotBooking: true,
      slotBookingClass: true,
      bookedSlots: false,
      bookedSlotsClass: false,
    },
    modalState: false,
    fullCalendar: false,
    attendanceForm: false,
    NoShowForm: false,
    studentname: "",
    StudioBookingID: "",
    scaleResult: "0",
    NoShow: false,
    loading: true,
    beforeOrAfter: "N/A",
  };

  AuthCreds = async (event) => {
    if (this.props.auth.user != null)  {
      const { user } = this.props.auth.user;

      await Auth.currentSession(user).then((res) => {
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        let idToken = res.getIdToken();
        this.setState({ token: idToken.jwtToken });
      });
    }
  };

  onFocus = (event) => {
    event.target.setAttribute('autocomplete', 'off');
    console.log(event.target.autocomplete);
  }

  handleAddProduct = async (BookingID, event) => {
    event.preventDefault();
    // add call to AWS API Gateway add product endpoint here
    NProgress.start();
    if (this.state.newproduct.bookingDate == "" || this.state.newproduct.bookingTime == "") {
      this.props.notification("Unable to add slot", "Please ensure you enter a date and time for your booking");
    } else {
    try {
      const params = {
        id: BookingID,
        bookingDate: this.state.newproduct.bookingDate,
        bookingTime: this.state.newproduct.bookingTime,
        username: this.props.auth.user.username.toLowerCase(),
      };

      const headers = {
        headers: { Authorization: this.state.token },
      };

      const res = await axios.post(
        `${config.api.invokeUrl}/bookings/{id}`,
        params,
        headers
      );
      this.setState({
        products: [...this.state.products, this.state.newproduct],
      });
      this.setState({
        newproduct: { bookingDate: "", BookingID: "", bookingTime: "" },
        ...this.state,
        modalState: false,
        fullCalendar: true,
      });
      this.props.notification("slot added!", "Thank you for using the Rap Therapy studio service. Your slot has been added and is available for booking");
    } catch (res) {
      if (res == "Error: Network Error") {
        this.props.notification("Access Denied", "You do not have access to create a studio booking. Please contact info@raptherapy.co.uk");
      } else {
      console.log("an error has occurred" + res);
      this.props.notification("Unable to add slot", res.response.data);
      }
    }
  }
    NProgress.done();
  };

  handleUpdateProduct = async (BookingID, name, time) => {
    // add call to AWS API Gateway update product endpoint here
    NProgress.start();
    try {
      const params = {
        id: BookingID,
        bookingDate: name,
        bookingTime: time,
      };

      const headers = {
        headers: { Authorization: this.state.token },
      };

      const bookingEvents = [...this.state.products].filter(
        (bookingEvent) => bookingEvent.BookingID == params.id
      );
      console.log(bookingEvents);
      console.log(params.id);
      console.log(bookingEvents[0].bookingDate);
      const bookingDate = bookingEvents[0].bookingDate;
      const todayDate = moment().format("YYYY-MM-DD");
      const dateRange = Moment.range(todayDate, bookingDate);
      const dayDiff = dateRange.diff("days");
      console.log(dayDiff);

      if (dayDiff <= 3) {
        this.props.notification("Unable to delete", "Your booking is within 3 days please contact booking@raptherapy.co.uk to cancel");
      } else {
      const res = await axios.patch(
        `${config.api.invokeUrl}/bookings/{id}`,
        params,
        headers
      );
      const productToUpdate = [...this.state.products].find(
        (product) => product.BookingID === BookingID
      );
      const updatedProducts = [...this.state.products].filter(
        (product) => product.BookingID !== BookingID
      );
      productToUpdate.bookingDate = name;
      productToUpdate.bookingTime = time;
      updatedProducts.push(productToUpdate);
      this.setState({ products: updatedProducts });
      this.props.notification("Booking Updated", res.data);
      }
    } catch (res) {
      console.log("unable to update" + res);
      this.props.notification("unable to update", res.response.data);
    }
    NProgress.done();
  };

  handleDeleteProduct = async (BookingID, updatedBookingDate, event) => {
    event.preventDefault();
    // add call to AWS API Gateway delete product endpoint here
    var id = BookingID
    NProgress.start();
    try {
      const headers = {
        headers: { Authorization: this.state.token },
      };

      const bookingDate = updatedBookingDate;
      const todayDate = moment().format("YYYY-MM-DD");
      const dateRange = Moment.range(todayDate, bookingDate);
      const dayDiff = dateRange.diff("days");
      console.log(dayDiff);

      if (dayDiff <= 3) {
        this.props.notification("Unable to delete", "Your booking is within 3 days please contact booking@raptherapy.co.uk to cancel");
      } else {
        const res = await axios.delete(
          `${config.api.invokeUrl}/bookings/${id}`,
          headers
        );
        const updatedProducts = [...this.state.products].filter(
          (product) => product.BookingID !== BookingID
        );
        this.setState({ products: updatedProducts });
        this.props.notification("Booking deleted", res.data);
      }
    } catch (res) {
      console.log("unable to update" + res);
      this.props.notification("unable to update", res.response.data);
    }
    NProgress.done();
  };

  updateFullCalendar = async () => {
    this.setState({ ...this.state, fullCalendar: false });
  };

  handleAttendanceForm = async (studentname, StudioBookingID, beforeOrAfter) => {
    this.setState({
      ...this.state,
      attendanceForm: true,
      studentname: studentname,
      studiobookingID: StudioBookingID,
      beforeOrAfter: beforeOrAfter,

    });
  };

  handleNoShowForm = async (studentname, StudioBookingID) => {
    this.setState({
      ...this.state,
      NoShowForm: true,
      studentname: studentname,
      studiobookingID: StudioBookingID,
    });
  };

  handleAttendanceFormSubmit = async (event) => {
    event.preventDefault();
    NProgress.start();
    try {
      const params = {
        AttendanceID:
        this.props.auth.user.attributes.sub + moment().format("x"),
        Studentname: this.state.studentname,
        ScaleResult: this.state.scaleResult,
        StudioBookingID: this.state.studiobookingID,
        RecordDate: moment().format("YYYY-MM-DD"),
        NoShow: this.state.NoShow,
        beforeOrAfter: this.state.beforeOrAfter
      };

      const res = await axios.post(`${config.api.invokeUrl}/attendance`, params);
      this.props.notification("Attendance, check!", "Thank you for completing the feeling indicator");
      this.setState({
        ...this.state,
        NoShow: false,
        attendanceForm: false,

      });
    } catch (err) {
      console.log("an error has occurred" + err);
    }
    NProgress.done();
  };


    handleNoShowFormSubmit = async (event) => {
    event.preventDefault();
    NProgress.start();
    try {
      const params = {
        AttendanceID:
        this.props.auth.user.attributes.sub + moment().format("x"),
        Studentname: this.state.studentname,
        ScaleResult: "0",
        StudioBookingID: this.state.studiobookingID,
        RecordDate: moment().format("YYYY-MM-DD"),
        NoShow: this.state.NoShow,
        beforeOrAfter: this.state.beforeOrAfter
      };

      const res = await axios.post(`${config.api.invokeUrl}/attendance`, params);
      this.setState({
        ...this.state,
        NoShowForm: false,
      });
      this.props.notification("No Show", "Thank you for letting us know and apologies for the no show. We endeavour to ensure this does not happen again.");
    } catch (err) {
      console.log("an error has occurred" + err);
    }
    NProgress.done();
  };

  fetchProducts = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      const username = this.props.auth.user.username;

      const headers = {
        headers: { Authorization: this.state.token },
      };

      const res = await axios.get(
        `${config.api.invokeUrl}/booking/${username}`,
        headers
      );
      const products = res.data;
      this.setState({ products: products, fullCalendar: true, loading: false });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ fullCalendar: true, loading: false });
    }
  };

  fetchStudioBookings = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      const studiousername = this.props.auth.user.username;

      const headers = {
        headers: { Authorization: this.state.token },
      };

      const res = await axios.get(
        `${config.api.invokeUrl}/studiobooking/${studiousername}`,
        headers
      );
      const studioBookings = res.data;
      this.setState({ studioBookings: studioBookings });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  onInputClick = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  onAddProductDateChange = (values) => {
    this.setState({
      newproduct: {
        ...this.state.newproduct,
        bookingDate: moment(values).format("YYYY-MM-DD"),
        BookingID: this.props.auth.user.attributes.sub + moment().format("x"),
      },
    });
  };
  onAddProductTimeChange = (values) => {
    this.setState({
      newproduct: {
        ...this.state.newproduct,
        bookingTime: moment(values).format("LT"),
      },
    });
  };

  onScaleChange = (e, data) => {
    this.setState({
      ...this.state,
      scaleResult: data,
    });
  };


  componentDidMount = () => {
    this.fetchStudioBookings();
    this.AuthCreds();
    this.fetchProducts();
  };

  render() {
    var slotBookingClassName = this.state.tabState.slotBookingClass
      ? "is-active"
      : "";
    var bookedSlotsClassName = this.state.tabState.bookedSlotsClass
      ? "is-active"
      : "";

    const columns = [
      {
        Header: "Student",
        accessor: "studentname",
        sortable: false,
        show: true,
      },
      {
        Header: "Date",
        accessor: "bookingDate",
        filterable: false,
        show: true,
      },
      {
        Header: "Time",
        accessor: "bookingTime",
        sortable: false,
        filterable: false,
        show: true,
      },
      {
        Header: "StudioBookingID",
        accessor: "StudioBookingID",
        show: false,
      },
      {
        Header: "Before Session",
        sortable: false,
        filterable: false,
        show: true,
        Cell: (props) => {
          return (
            <button
              className="button is-success"
              onClick={() => {
                this.handleAttendanceForm(
                  props.original.studentname,
                  props.original.StudioBookingID,
                  "before"
                );
              }}
            >
              <span class="icon is-small">
                <i class="fas fa-check"> </i>
              </span>
            </button>
          );
        },
        style: {
          textAlign: "center",
        },
        width: 120,
      },
      {
        Header: "After Session",
        sortable: false,
        filterable: false,
        show: true,
        Cell: (props) => {
          return (
            <button
              className="button is-success"
              onClick={() => {
                this.handleAttendanceForm(
                  props.original.studentname,
                  props.original.StudioBookingID,
                  "after"
                );
                
              }}
            >
              <span class="icon is-small">
                <i class="fas fa-check"> </i>
              </span>
            </button>
          );
        },
        style: {
          textAlign: "center",
        },
        width: 120,
      },
      {
        Header: "No Show",
        sortable: false,
        filterable: false,
        show: true,
        Cell: (props) => {
          return (
            <button
              className="button is-success"
              onClick={() => {
                this.handleNoShowForm(
                  props.original.studentname,
                  props.original.StudioBookingID
                );
              }}
            >
              <span class="icon is-small">
                <i class="fas fa-check"> </i>
              </span>
            </button>
          );
        },
        style: {
          textAlign: "center",
        },
        width: 120,
      },
    ];

    const PrettoSlider = withStyles({
      root: {
        color: "#52af77",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },

      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    })(Slider);

    const marks = [
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
    ];

    return (
      <Fragment>
        <div className="tabs is-centered is-boxed">
          <ul>
            <li className={slotBookingClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      slotBooking: true,
                      slotBookingClass: true,
                    },
                  })
                }
              >
                Slot Registry
              </a>
            </li>
            <li className={bookedSlotsClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      bookedSlots: true,
                      bookedSlotsClass: true,
                    },
                  })
                }
              >
                Upcoming Sessions
              </a>
            </li>
          </ul>
        </div>
        <div>
			  <Notification
			  />
		  </div>
        {this.state.tabState.slotBooking && this.props.auth.isAuthenticated && (
          <React.Fragment>
            <div class="modal">
              <Modal
                isOpen={this.state.modalState}
                onRequestClose={() =>
                  this.setState({
                    ...this.state,
                    modalState: false,
                    fullCalendar: true,
                  })
                }
              >
                <div class="modal-background"></div>
                <div class="modal-card">
                  <header class="modal-card-head">
                    <p class="modal-card-title">Studio Booking Form</p>
                    <button
                      class="delete"
                      aria-label="close"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          modalState: false,
                          fullCalendar: true,
                        })
                      }
                    ></button>
                  </header>
                  <form
                    onSubmit={(event) =>
                      this.handleAddProduct(
                        this.state.newproduct.BookingID,
                        event
                      )
                    }
                  >
                    <div className="modal-card-body">
                      <p>Select one of the available dates:</p>
                      <DatePicker
                      style={{ position:"relative", zIndex:"999" }}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                        id="date"
                        className="input"
                        placeholder=""
                        onFocus={this.onFocus}
                        value={this.state.newproduct.bookingDate}
                        onChange={this.onAddProductDateChange}
                        minDate={new Date()}
                        name="bookingDate"
                        dateFormat="YYYY-MM-DD"
                      />

                      <p>Select one of the available times:</p>

                      <DatePicker
                        style={{ position:"relative", zIndex:"999" }}
                        popperModifiers={{
                        preventOverflow: {
                        enabled: true,
                        },
                        }}
                        id="time"
                        className="input"
                        onFocus={this.onFocus}
                        placeholder="Enter Date"
                        value={this.state.newproduct.bookingTime}
                        onChange={this.onAddProductTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                    <footer class="modal-card-foot">
                      <button
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            modalState: false,
                            calendarDates: [],
                            fullCalendar: true,
                          })
                        }
                        class="button"
                      >
                        {" "}
                        close{" "}
                      </button>
                      <button class="button is-dark"> submit </button>
                    </footer>
                  </form>
                </div>
              </Modal>
            </div>
            <div class="field-label"></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <button
                    type="submit"
                    className="button is-dark"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        modalState: true,
                        calendarDates: [],
                        fullCalendar: false,
                      })
                    }
                  >
                    + Add Studio Slot
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      {this.state.loading && (
        <div class="section">
          <div class="container">
            <div class="columns is-centered">
              <BarLoader loading />
            </div>
          </div>
        </div>
      )}

        {this.state.fullCalendar &&
          this.state.tabState.slotBooking &&
          this.props.auth.isAuthenticated && (
            <div className="container py-5">
              <EventCalendar
                isAdmin={true}
                handleUpdateProduct={this.handleUpdateProduct}
                handleDeleteProduct={this.handleDeleteProduct}
                updateFullCalendar={this.updateFullCalendar}
                name={this.state.products}
              />
            </div>
          )}
        <div class="modal">
          <Modal
            isOpen={this.state.attendanceForm}
            onRequestClose={() =>
              this.setState({
                ...this.state,
                attendanceForm: false,
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Feeling Indicator</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      attendanceForm: false,
                      fullCalendar: true,
                    })
                  }
                ></button>
              </header>
              <form
                onSubmit={(event) => this.handleAttendanceFormSubmit(event)}
              >
                <div className="modal-card-body">
                  <Typography gutterBottom>
                    How are you feeling today?
                  </Typography>
                  <Slider
                    value={this.state.scaleResult}
                    onChange={this.onScaleChange}
                    id="scaleResult"
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={1}
                    min={1}
                    max={10}
                    step={1}
                    marks={marks}
                  />
                </div>
                <footer class="modal-card-foot">
                  <button
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        attendanceForm: false,
                        fullCalendar: true,
                      })
                    }
                    class="button"
                  >
                    {" "}
                    close{" "}
                  </button>
                  <button class="button is-dark"> submit </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>
        <div class="modal">
          <Modal
            isOpen={this.state.NoShowForm}
            onRequestClose={() =>
              this.setState({
                ...this.state,
                NoShowForm: false,
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">No Show</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      NoShowForm: false,
                      fullCalendar: true,
                    })
                  }
                ></button>
              </header>
              <form
                onSubmit={(event) => this.handleNoShowFormSubmit(event)}
              >
                <div className="modal-card-body">

                <div className="field">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            id="NoShow"
                            value={this.state.NoShow}
                            onClick={this.onInputClick}
                          />
                             Please confirm {this.state.studentname} did not the studio session
                        </label>
                      </div>

                </div>
                <footer class="modal-card-foot">
                  <button
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        NoShowForm: false,
                        fullCalendar: true,
                      })
                    }
                    class="button"
                  >
                    {" "}
                    close{" "}
                  </button>
                  <button class="button is-dark"> submit </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>
        {this.state.tabState.bookedSlots && this.props.auth.isAuthenticated && (
          <div className="container py-5">
            <ReactTable
              columns={columns}
              data={this.state.studioBookings.Items}
              filterable
              defaultPageSize={5}
              noDataText={"No bookings found"}
            ></ReactTable>
          </div>
        )}
        {!this.props.auth.isAuthenticated && (
          <p>You are not authorised to access this page</p>
        )}
      </Fragment>
    );
  }
}
