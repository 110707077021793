import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.min.css';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import config from "./config";
import * as serviceWorker from './serviceWorker';

// check if user is logged, if user is logged in use current details to set the auth creds
if (localStorage.getItem('amplify-user-pool-id')) {
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.region.region,
    userPoolId: localStorage.getItem('amplify-user-pool-id'),
    userPoolWebClientId: localStorage.getItem('amplify-user-app-id')
  }
});
} else {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.region.region,
      userPoolId: config.studiocognito.userPoolId,
      userPoolWebClientId: config.studiocognito.APP_CLIENT_ID
    }

});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
