import React, { Component } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import SchoolValidate from "./utility/AdminFormSchoolValidation";
import StudioValidate from "./utility/AdminFormStudioValidation";
import FormErrors from "./utility/AdminFormErrors";
import Notification from "./Notifications.js";  
import NProgress from 'nprogress'; 
const config = require("../config.json");


export default class AdminPage extends Component {
  state = {
    schoolname: "",
    username: "",
    email: "",
    token: "",
    studioname: "",
    studiousername: "",
    studioemail: "",
    firstName: "",
    lastName: "",
    studiofirstName: "",
    studiolastName: "",
    postcode: "",
    address: "",
    borough: "",
    town: "",
    studiopostcode: "",
    studioaddress: "",
    studioborough: "",
    studiotown: "",
    phoneNumber: "",
    safeguarding: false,
    dbsclearance: false,
    studiophoneNumber: "",
    schoolType: "",
    tabState: {
      studioRegistry: false,
      studioRegistryClass: false,
      schoolRegistry: true,
      schoolRegistryClass: true,
    },
    schoolErrors: {
      cognito: null,
      blankfield: false,
      phonenumber: false,
      postcode: false,
      usernamespaces: false,
    },
    studioErrors: {
      cognito: null,
      blankfield: false,
      phonenumber: false,
      postcode: false,
      dbscleared: false,
      safeguarded: false,
      usernamespaces: false,
    },
  };


  clearSchoolErrorState = () => {
    this.setState({
      schoolErrors: {
        cognito: null,
        blankfield: false,
        phonenumber: false,
        postcode: false,
        usernamespaces: false,
      }
    });
  };

  clearStudioErrorState = () => {
    this.setState({
      studioErrors: {
        cognito: null,
        blankfield: false,
        phonenumber: false,
        postcode: false,
        dbscleared: false,
        safeguarded: false,
        usernamespaces: false,
      }
    });
  };
  

  AuthCreds = async (event) => {
    if (this.props.auth.user != null) {
      const { user } = this.props.auth.user;

      await Auth.currentSession(user).then((res) => {
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        let idToken = res.getIdToken();
        this.setState({ token: idToken.jwtToken });
      });
    }
  };

  schoolHandleSubmit = async (event) => {
    event.preventDefault();
    // Form validation

    this.clearSchoolErrorState();
    const error = SchoolValidate(event, this.state);
    if (error) {
      this.setState({
        schoolErrors: { ...this.state.schoolErrors, ...error }
      });
    } else {
    // add call to AWS API Gateway update product endpoint here
    var username1 = this.state.username;
    var username = username1.toLowerCase();
    NProgress.start();
    var schoolname = this.state.schoolname.toLowerCase();
    try {
      const params = {
        schoolname: schoolname,
        username: username,
        email: this.state.email,
        group: "School",
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        postcode: this.state.postcode,
        address: this.state.address,
        borough: this.state.borough,
        town: this.state.town,
        phonenumber: this.state.phoneNumber,
        schooltype: this.state.schoolType,
        id: this.state.username + moment().format("x"),
      };

      const headers = {
        headers: { Authorization: this.state.token },
      };
      
      const res = await axios.post(`${config.api.invokeUrl}/schools`, params, headers);
      this.props.notification("School created!", res.data);
    } catch (res) {
      if (res == "Error: Network Error") {
        this.props.notification("Access Denied", "You do not have access to create a school. Please contact info@raptherapy.co.uk");
      } else {
      console.log("unable to register user" + res);
      this.props.notification("Unable to create school", res.response.data); 
      }
    }
    NProgress.done();
  }
  };

  studioHandleSubmit = async (event) => {
    event.preventDefault();
    this.clearStudioErrorState();
    const error = StudioValidate(event, this.state);
    if (error) {
      this.setState({
        studioErrors: { ...this.state.errors, ...error }
      });
    } else {
    // add call to AWS API Gateway update product endpoint here
    NProgress.start();
    try {
      const params = {
        id: this.state.studiousername + moment().format("x"),
        studioname: this.state.studioname,
        username: this.state.studiousername.toLowerCase(),
        email: this.state.studioemail,
        group: "Studio",
        studiofirstname: this.state.studiofirstName,
        studiolastname: this.state.studiolastName,
        studiopostcode: this.state.studiopostcode,
        studioaddress: this.state.studioaddress,
        studioborough: this.state.studioborough,
        studiotown: this.state.studiotown,
        safeguarding: this.state.safeguarding,
        dbsclearance: this.state.dbsclearance,
        studiophonenumber: this.state.studiophoneNumber
      };

      const headers = {
        headers: { Authorization: this.state.token },
      };
      const res = await axios.post(`${config.api.invokeUrl}/studios`, params, headers);
      console.log("test");
      this.props.notification("Studio created!", res.data);
      this.setState({ state: { studioname: "", username: "", email: "" } });
    } catch (res) {
      if (res == "Error: Network Error") {
        this.props.notification("Access Denied", "You do not have access to create a studio. Please contact info@raptherapy.co.uk");
      } else {
        this.props.notification("Unable to create studio", res.response.data);
      }
    }
    NProgress.done();
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  onInputClick = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidMount = () => {
    this.AuthCreds();
  };

  render() {
    var studioRegistryClassName = this.state.tabState.studioRegistryClass
      ? "is-active"
      : "";
    var schoolRegistryClassName = this.state.tabState.schoolRegistryClass
      ? "is-active"
      : "";
    return (
      <section className="section auth">
        <div className="tabs is-centered is-boxed">
          <ul>
            <li className={schoolRegistryClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      schoolRegistry: true,
                      schoolRegistryClass: true,
                    },
                  })
                }
              >
                School Registry
              </a>
            </li>
            <li className={studioRegistryClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      studioRegistry: true,
                      studioRegistryClass: true,
                    },
                  })
                }
              >
                Studio Registry
              </a>
            </li>
          </ul>
        </div>
        <div>
			  <Notification
			  />
		  </div>
        <div className="columns is-mobile is-centered">
          {this.state.tabState.schoolRegistry &&
            this.props.auth.isAuthenticated && (
              <React.Fragment>
                <form onSubmit={(event) => this.schoolHandleSubmit(event)}>
                <FormErrors formerrors={this.state.schoolErrors} />
                  <div class="field is-horizontal">

                    <div class="field-label is-normal">
                      <label class="label">Name</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="schoolname"
                            aria-describedby="userNameHelp"
                            placeholder="Name of school"
                            value={this.state.schoolname}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="username"
                            aria-describedby="userNameHelp"
                            placeholder="school username"
                            value={this.state.username}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="firstName"
                            aria-describedby="userNameHelp"
                            placeholder="First Name"
                            value={this.state.firstName}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="lastName"
                            aria-describedby="userNameHelp"
                            placeholder="Last Name"
                            value={this.state.lastName}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Address</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="postcode"
                            aria-describedby="userNameHelp"
                            placeholder="Postcode"
                            value={this.state.postcode}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="address"
                            aria-describedby="userNameHelp"
                            placeholder="First Line of Address"
                            value={this.state.address}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="borough"
                            aria-describedby="userNameHelp"
                            placeholder="Borough"
                            value={this.state.borough}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="town"
                            aria-describedby="userNameHelp"
                            placeholder="County"
                            value={this.state.town}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Phone Number</label>
                    </div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                              id="phoneNumber"
                              class="input"
                              type="tel"
                              placeholder="School Phone Number"
                              value={this.state.phoneNumber}
                              onChange={this.onInputChange}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">School email</label>
                    </div>
                    <div class="field-body">
                      <div className="field">
                        <div class="control">
                          <p className="control has-icons-left has-icons-right">
                            <input
                              className="input"
                              type="email"
                              id="email"
                              aria-describedby="emailHelp"
                              placeholder="Enter School E-mail"
                              value={this.state.email}
                              onChange={this.onInputChange}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div className="field">
                        <div class="control">
                          <p className="control has-icons-left has-icons-right">
                            <div class="select">
                              <select
                                name="schoolType"
                                id="schoolType"
                                value={this.state.schoolType}
                                onChange={this.onInputChange}
                                required
                              >
                                <option selected disabled value="">
                                  Choose a School Type
                                </option>
                                <option value="PrimarySchool">
                                  Primary School
                                </option>
                                <option value="SecondarySchool">
                                  Secondary School
                                </option>
                                <option value="SpecialSchool">
                                  Special School
                                </option>
                                <option value="PRU">
                                  PRU
                                </option>
                                <option value="Other">
                                  Other
                                </option>
                              </select>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <button className="button is-dark">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}

          {this.state.tabState.studioRegistry &&
            this.props.auth.isAuthenticated && (
              <React.Fragment>
                <form onSubmit={(event) => this.studioHandleSubmit(event)}>
                <FormErrors formerrors={this.state.studioErrors} />
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Name</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="studioname"
                            aria-describedby="userNameHelp"
                            placeholder="Name of Studio"
                            value={this.state.studioname}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="studiousername"
                            aria-describedby="userNameHelp"
                            placeholder="Studio Username"
                            value={this.state.studiousername}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="studiofirstName"
                            aria-describedby="userNameHelp"
                            placeholder="First Name"
                            value={this.state.studiofirstName}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="studiolastName"
                            aria-describedby="userNameHelp"
                            placeholder="Last Name"
                            value={this.state.studiolastName}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Address</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="studiopostcode"
                            aria-describedby="userNameHelp"
                            placeholder="Postcode"
                            value={this.state.studiopostcode}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="studioaddress"
                            aria-describedby="userNameHelp"
                            placeholder="First Line of Address"
                            value={this.state.studioaddress}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="studioborough"
                            aria-describedby="userNameHelp"
                            placeholder="Borough"
                            value={this.state.studioborough}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="studiotown"
                            aria-describedby="userNameHelp"
                            placeholder="County"
                            value={this.state.studiotown}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>


                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Studio email</label>
                    </div>
                    <div class="field-body">
                      <div className="field">
                        <div class="control">
                          <p className="control has-icons-left has-icons-right">
                            <input
                              className="input"
                              type="email"
                              id="studioemail"
                              aria-describedby="emailHelp"
                              placeholder="Enter Studio E-mail"
                              value={this.state.studioemail}
                              onChange={this.onInputChange}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Phone Number</label>
                    </div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                              id="studiophoneNumber"
                              class="input"
                              type="tel"
                              placeholder="Studio Phone Number"
                              value={this.state.studiophoneNumber}
                              onChange={this.onInputChange}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Security Clearance</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <label class="checkbox">
                          <input
                            className="checkbox"
                            type="checkbox"
                            id="safeguarding"
                            value={this.state.safeguarding}
                            onClick={this.onInputClick}
                          />
                            Has the studio carried safeguarding training?
                        </label>
                      </div>

                      <div className="field">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            id="dbsclearance"
                            value={this.state.dbsclearance}
                            onClick={this.onInputClick}
                          />
                             Do you have a dbs dated within the last 2 years?
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <button className="button is-dark">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}
        </div>

        {!this.props.auth.isAuthenticated && (
          <p>You are not authorised to access this page</p>
        )}
      </section>
    );
  }
};