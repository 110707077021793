import React, { Component } from 'react';
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";

class Register extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { username, email, password } = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          'custom:pwchange': 'N'
        }
      });
      this.props.history.push("/welcome");
      console.log(signUpResponse);
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
<div class="pricing-table">
  <div class="pricing-plan">
    <div class="plan-header">Starter</div>
    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>20</span>/month</div>
    <div class="plan-items">
      <div class="plan-item">20GB Storage</div>
      <div class="plan-item">100 Domains</div>
      <div class="plan-item">-</div>
      <div class="plan-item">-</div>
    </div>
    <div class="plan-footer">
      <button class="button is-fullwidth" disabled="disabled">Current plan</button>
    </div>
  </div>

  <div class="pricing-plan is-warning">
    <div class="plan-header">Startups</div>
    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>40</span>/month</div>
    <div class="plan-items">
      <div className="plan-item">20GB Storage</div>
      <div class="plan-item">25 Domains</div>
      <div class="plan-item">1TB Bandwidth</div>
      <div class="plan-item">-</div>
    </div>
    <div class="plan-footer">
      <button class="button is-fullwidth">Choose</button>
    </div>
  </div>

  <div class="pricing-plan is-active">
    <div class="plan-header">Growing Team</div>
    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>60</span>/month</div>
    <div class="plan-items">
      <div class="plan-item">200GB Storage</div>
      <div class="plan-item">50 Domains</div>
      <div class="plan-item">1TB Bandwidth</div>
      <div class="plan-item">100 Email Boxes</div>
    </div>
    <div class="plan-footer">
      <button class="button is-fullwidth">Choose</button>
    </div>
  </div>

  <div class="pricing-plan is-danger">
    <div class="plan-header">Enterprise</div>
    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>100</span>/month</div>
    <div class="plan-items">
      <div class="plan-item">2TB Storage</div>
      <div class="plan-item">100 Domains</div>
      <div class="plan-item">1TB Bandwidth</div>
      <div class="plan-item">1000 Email Boxes</div>
    </div>
    <div class="plan-footer">
      <button class="button is-fullwidth">Choose</button>
    </div>
  </div>
</div>
    );
  }
}

export default Register;