import React from 'react';

export default function Welcome() {
  return (
    <section className="section auth">
      <div className="container">
        <h1>Welcome!</h1>
        <p>You have successfully registered a new account.</p>
        <p>We've sent you two emails. One contains details to verify your e-mail, after verifying you'll recieve another e-mail to set your password</p>
      </div>
    </section>
  )
}
