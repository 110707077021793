import React from "react";

function FormErrors(props) {
  if (
    props.formerrors &&
    (props.formerrors.blankfield || props.formerrors.passwordmatch)
  ) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center help is-danger">
          {props.formerrors.blankfield ? "All fields are required" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.usernamespaces) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.usernamespaces ? "Username must not have spaces" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.phonenumber) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.phonenumber ? "Please enter a valid phone number" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.semhother) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.semhother ? "Please enter a value for other" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.learningneedsother) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.learningneedsother ? "Please enter a value for other" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.medicalneedsothers) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.medicalneedsothers ? "Please enter a value for other is medical needs is selected" : ""}
        </div>
      </div>
    );
  }
  else {
    return <div />;
  }
}

export default FormErrors;