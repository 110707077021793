import React, { Component, Fragment } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment";

export default class EventCalendar extends Component {
  state = {
    events: [],
    modalState: false,
    fullCalendar: true,
    BookingID: "",
    updatedBookingDate: "",
    updatedBookingTime: "",
  };

  createDates = async () => {
    const events = [];
    for (var i in this.props.name) {
      events.push({
        id: this.props.name[i].BookingID,
        date: this.props.name[i].bookingDate,
        title: this.props.name[i].bookingTime,
      });
    }
    this.setState({ ...this.state, events: events });
  };

  handleDateClick = async (id, date, time) => {
    this.setState({
      ...this.state,
      fullCalendar: false,
      modalState: true,
      BookingID: id,
      updatedBookingDate: moment(date).format("YYYY-MM-DD"),
      updatedBookingTime: time,
    });
  };

  handleEditSave = event => {
    event.preventDefault();
    this.props.handleUpdateProduct(this.state.BookingID, this.state.updatedBookingDate, this.state.updatedBookingTime);
    this.setState({...this.state,modalState: false, fullCalendar: true })
  }

  handleDelete = event => {
    event.preventDefault();
    this.props.handleDeleteProduct(this.state.BookingID, this.state.updatedBookingDate, event);
    this.setState({...this.state,modalState: false, fullCalendar: true })
  }

  onAddBookingDate = values => this.setState({...this.state, "updatedBookingDate": moment(values).format("YYYY-MM-DD") });
  onAddBookingTime = values => this.setState({...this.state, "updatedBookingTime": moment(values).format("LT") });

  componentDidMount = () => {
    this.createDates();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.createDates(this.props.name);
    }
  }

  render() {
    return (
      <Fragment>
        <div class="modal">
          <Modal
            isOpen={this.state.modalState}
            onRequestClose={() =>
              this.setState({
                studio: { ...this.state, modalState: false },
                calendarDates: [],
              })
            }
          >
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Update Booking Slot</p>
                <button
                  class="delete"
                  aria-label="close"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      modalState: false,
                      fullCalendar: true,
                    })
                  }
                ></button>
              </header>
              <form onSubmit={(event) => this.handleEditSave(event)}>
                <div className="modal-card-body">
                  <p>Select one of the available dates:</p>
                  <DatePicker
                    id="date"
                    placeholder="Select"
                    value={this.state.updatedBookingDate}
                    onChange={this.onAddBookingDate}
                    minDate={new Date()}
                    name="bookingDate"
                    dateFormat="YYYY-MM-DD"
                  />
                  <p>Select one of the available times:</p>
                  <DatePicker
                    id="time"
                    placeholder="Select Time"
                    value={this.state.updatedBookingTime}
                    onChange={this.onAddBookingTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>

                <footer class="modal-card-foot">
                  <button
                    onClick={(event) => this.handleDelete(event)}
                    class="button"
                  >
                    {" "}
                    Delete{" "}
                  </button>
                  <button class="button is-dark"> Update </button>
                </footer>
              </form>
            </div>
          </Modal>
        </div>

        {this.state.fullCalendar && (
          <FullCalendar
            plugins={[dayGridPlugin]}
            eventClick={(args) => this.handleDateClick(args.event.id, args.event.start, args.event.title)}

            events={this.state.events}
          />
        )}
      </Fragment>
    );
  }
}
