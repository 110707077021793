import React, { Component, Fragment} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import SchoolPortal from './components/SchoolPortal';
import FirstLogin from './components/auth/FirstLogin';
import ProductAdmin from './components/ProductAdmin';
import AdminPage from './components/AdminPage';
import StudioRegistry from './components/auth/StudioRegistry';
import SchoolRegistry from './components/auth/SchoolRegistry';
import ParentRegistry from './components/auth/ParentRegistry';
import StudentRegistry from './components/auth/StudentRegistry';
import StudioListing from './components/StudioListing';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import Welcome from './components/auth/Welcome';
import Footer from './components/Footer';
import { Auth } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
library.add(faEdit);

class App extends Component {


  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
  }


  handleOnClickDefault = (title, message) =>{
    store.addNotification({
        title: title,
        message: message,
        type: "info",
        container: "top-center",
        insert:"top",
        animationIn:["animated", "fadeIn"],
        animationOut:["animated", "fadeOut"],
        dismiss:{
            duration: 2000,
            showIcon: true,
            pauseOnHover: true,
            touch: true,
            click: true,
            
        },
        width: 400
    })
}
  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch(error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    return (
      !this.state.isAuthenticating &&
      <div >
        <Router>
          <div>
            <Switch>
            <Route exact path="/" render={(props) => <Home {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
            <Route exact path="/firstlogin" render={(props) => <FirstLogin {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
            <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
            <Fragment>
            <Navbar auth={authProps} />
              <Route exact path="/schoolportal" render={(props) => <SchoolPortal {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/admin" render={(props) => <ProductAdmin {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/login" render={(props) => <LogIn {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/register" render={(props) => <Register {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/changepassword" render={(props) => <ChangePassword {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/changepasswordconfirmation" render={(props) => <ChangePasswordConfirm {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/welcome" render={(props) => <Welcome {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/studioregistry" render={(props) => <StudioRegistry {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/schoolregistry" render={(props) => <SchoolRegistry {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/parentregistry" render={(props) => <ParentRegistry {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/studentregistry" render={(props) => <StudentRegistry {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/adminpage" render={(props) => <AdminPage {...props} auth={authProps} notification={this.handleOnClickDefault}/>} />
              <Route exact path="/StudioListing" render={(props) => <StudioListing {...props} auth={authProps} notification={this.handleOnClickDefault} removeNotification={this.removeNotification}/>} />
            </Fragment>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
