import React from "react";

function FormErrors(props) {
  if (
    props.formerrors &&
    (props.formerrors.blankfield || props.formerrors.passwordmatch)
  ) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center help is-danger">
          {props.formerrors.blankfield ? "All fields are required" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.phonenumber) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.phonenumber ? "Please enter a valid phone number" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.usernamespaces) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.usernamespaces ? "Username must not have spaces" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.postcode) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.postcode ? "Please enter a valid postcode" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.dbscleared) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.dbscleared ? "Studio must be dbs cleared" : ""}
        </div>
      </div>
    );
  }
  else if (props.formerrors && props.formerrors.safeguarded) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
        {props.formerrors.safeguarded ? "Studio must have completed safe guarding training" : ""}
        </div>
      </div>
    );
  }
  else {
    return <div />;
  }
}

export default FormErrors;