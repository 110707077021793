function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }

  if (state.hasOwnProperty("name") && state.name === "") {
    document.getElementById("name").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("number") && state.number === "") {
    document.getElementById("number").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("email") && state.email === "") {
    document.getElementById("email").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("referType") && state.referType === "") {
    document.getElementById("referType").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("fullname") && state.fullname === "") {
    document.getElementById("fullname").classList.add("is-danger");
    return { blankfield: true };
  }

  return;
}

export default validateForm;