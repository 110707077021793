import React, { Component } from 'react';
import Validate from "../utility/FormValidation";
import FormErrors from "../utility/FormErrors";
import { Auth } from "aws-amplify";
import NProgress from 'nprogress'; 
const config = require('../../config.json');

class StudioFirstLogIn extends Component {
  state = {
    username: "",
    password: "",
    new_password: "",
    group: "",
    errors: {
      cognito: null,
      passwordLength: false,
      passwordCap: false,
      passwordNum: false,
      passwordLow: false,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        passwordLength: false,
        passwordCap: false,
        passwordNum: false,
        passwordLow: false,
        blankfield: false
      }
    });
  };

  handleSubmit = async (event) => {
    NProgress.start();
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    } else {

    // AWS Cognito integration here
    try {
      var group = this.state.group
      var region = config.region.region
      var APP_CLIENT_ID = ""
      var userPoolId = ""

      switch(group) {
        case "Studio":
          APP_CLIENT_ID = config.studiocognito.APP_CLIENT_ID
          userPoolId = config.studiocognito.userPoolId
          break;
        case "School":
          APP_CLIENT_ID = config.teachercognito.APP_CLIENT_ID
          userPoolId = config.teachercognito.userPoolId
          break;
        case "Parent":
          APP_CLIENT_ID = config.parentcognito.APP_CLIENT_ID
          userPoolId = config.parentcognito.userPoolId
          break;
        case "Admin":
          APP_CLIENT_ID = config.admincognito.APP_CLIENT_ID
          userPoolId = config.admincognito.userPoolId
          break;
      }

      Auth.configure(
        {
          Auth: {
            mandatorySignIn: true,
            region: region,
            userPoolId: userPoolId,
            userPoolWebClientId: APP_CLIENT_ID
          }
        });
      const user = await Auth.signIn(this.state.username, this.state.password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, this.state.new_password);
            this.props.auth.setAuthStatus(true);
            this.props.auth.setUser(user);
            this.props.history.push({
              pathname: '/',
              state: { loginData: "Your account has successfully been activated. Please use your new details to login" }
            });

            localStorage.setItem('amplify-user-pool-id', userPoolId);
            localStorage.setItem('amplify-user-app-id', APP_CLIENT_ID);
 };
});
    
    }catch(error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
    NProgress.done();
  }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1>Account Setup</h1>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="password"
                  id="password"
                  placeholder="Temporary Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="password"
                  id="new_password"
                  placeholder="New Password"
                  value={this.state.new_password}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
              <div class="select">
              <select name="group" id="group" value={this.state.group} onChange={this.onInputChange}>
              <option selected disabled value="">Choose a group</option>
                <option value="Studio">Studio</option>
                <option value="School">School</option>
                <option value="Parent">Parent</option>
                <option value="Admin">Admin</option>
                </select>
                </div>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-dark">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default StudioFirstLogIn;