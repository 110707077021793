import React, { Component } from 'react';
import Validate from "../utility/FormValidation";
import FormErrors from "../utility/FormErrors";
import axios from "axios";
import Notification from "../Notifications.js";
import { Auth } from 'aws-amplify';
const config = require('../../config.json');

class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    group: "",
    email: "",
    newpassword: "",
    errors: {
      cognito: null,
      newpasswordLength: false,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        passwordLength: false,
        blankfield: false
      }
    });
  };


    passwordVerificationHandler = async event => {
      event.preventDefault();
      // Form validation
      this.clearErrorState();
      const error = Validate(event, this.state);
      if (error) {
        this.setState({
          errors: { ...this.state.errors, ...error }
        });
      }
  
      try {
        const params = {
          email: this.state.email,
          group: this.state.group,
          newpassword: this.state.newpassword,
          verificationcode: this.state.verificationcode,
        };
  
        const headers = {
          headers: { "Content-Type":"application/json" },
        };
  
        const res = await axios.post(`${config.api.invokeUrl}/passwordresetconfirmation`, params, headers);
        this.props.history.push('/');
      }catch(res) {
        console.log("test:" + res);
       // this.props.notification("Unable to reset password", error);
        
      }
    }


  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
                          <div>
			  <Notification
			  />
        </div>
        <div className="container">
          <h1>Set new password</h1>
          <p>
            Please check enter the verification code sent to your email address below,
            your email address and a new password.
          </p>
          <form onSubmit={this.passwordVerificationHandler}>
          <FormErrors formerrors={this.state.errors} />
            <div className="field">
              <p className="control">
                <input
                  type="text"
                  className="input"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                  required
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  type="password"
                  className="input"
                  id="newpassword"
                  placeholder="New password"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
              <div class="select">
              <select name="group" id="group" value={this.state.group} onChange={this.onInputChange} required>
              <option disabled selected value="">Choose a group</option>
                <option value="Studio">Studio</option>
                <option value="School">School</option>
                <option value="Parent">Parent</option>
                <option value="Admin">Admin</option>
                </select>
                </div>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-dark">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPasswordVerification;