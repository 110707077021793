import React, { Component, Fragment } from "react";
import axios from "axios";
import Studios from "./Studios";
import Pagination from "./Pagination";
import moment from "moment";
import { BarLoader } from "react-spinners";
import { extendMoment } from "moment-range";
import StudioBookingCalendar from "./StudioBookingCalendar";
import Notification from "./Notifications.js";
import ReactTable from "react-table-6";
import 'react-notifications-component/dist/theme.css';
import "react-table-6/react-table.css";
import NProgress from 'nprogress'; 
const config = require("../config.json");
const Moment = extendMoment(moment);

export default class StudioListing extends Component {
  state = {
    studio: {
      loading: false,
      currentPage: 1,
      postsPerPage: 4,
    },
    studios: [],
    studioBookings: [],
    Bookings: [],
    calendarDates: [],
    studentList: [],
    StudioBookingCalendar: false,
    tabState: {
      bookSession: true,
      bookSessionClass: true,
      currentSessions: false,
      currentSessionsClass: false,
    },
    handleOutcome : {
      title: "",
      message: "",
    }
  };



  handleSubmit = async (updatedBookingDate, updatedBookingTime, studentname, studio, alert) => {
    // add call to AWS API Gateway update product endpoint here
    NProgress.start();
    try {
      const params = {
        bookingDate: updatedBookingDate,
        bookingTime: updatedBookingTime,
        id: updatedBookingDate + moment().format("x"),
        studentname: studentname,
        username: this.props.auth.user.username,
        studiousername: studio,
        group: this.props.auth.user.attributes['custom:cognitoGroup']
      };

      var res = await axios.post(`${config.api.invokeUrl}/studiobookings`, params);
      this.fetchStudioBooking();
      this.props.notification("Booking Sucessful!", res.data);
    } catch (res) {
      //this.setState({ handleOutcome: { title:"Unable to book", message: res.response.data } });
      this.props.notification("Booking Unsuccessful!", res.response.data);
      console.log("unable to book studio" + res);
    }
    NProgress.done();
  };

  fetchProducts = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      this.setState({ studio: { ...this.state.studio, loading: true } });
      const res = await axios.get(`${config.api.invokeUrl}/studios/`);
      const studios = res.data;
      this.setState({ studios: studios });
      this.setState({ studio: { ...this.state.studio, loading: false } });
    } catch (err) {
    //  this.setState({...this.state, StudioBookingCalendar: true})
      console.log(`An error has occurred: ${err}`);
    }
  };

  fetchBookings = async (username, event) => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {

      const res = await axios.get(
        `${config.api.invokeUrl}/booking/`
      );

      this.setState({Bookings: res.data  });

      this.setState({...this.state, StudioBookingCalendar: true})
    } catch (err) {
      this.setState({...this.state, StudioBookingCalendar: true})
      console.log(`An error has occurred: ${err}`);
    }
  };

  fetchStudent = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      var username = this.props.auth.user.username;
      var group = this.props.auth.user.attributes['custom:cognitoGroup'];
      username.toLowerCase();
      const res = await axios.get(
        `${config.api.invokeUrl}/students/${username}?group=${group}`
      );

      this.setState({
        studentList: res.data.Items,
      });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };


  fetchStudioBooking = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      var username = this.props.auth.user.username;
      username.toLowerCase();
      var group = this.props.auth.user.attributes['custom:cognitoGroup'];

      const res = await axios.get(
        `${config.api.invokeUrl}/studiobookings/${username}?group=${group}`
      );
      const studioBookings = res.data.Items;
      this.setState({ studioBookings: studioBookings });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  filterBookings = async (username) => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    const studioBookingDates = [];
    this.setState({...this.state, calendarDates: []})

    try {
      const updatedBookings = [...this.state.Bookings].filter(
        (booking) => booking.username.toUpperCase() === username.toUpperCase()
      );
        
      for (var i in updatedBookings) {
        studioBookingDates.push({
          id: updatedBookings[i].BookingID,
          date: updatedBookings[i].bookingDate,
          title: updatedBookings[i].bookingTime,
          studio: updatedBookings[i].username
        });
        this.setState({...this.state, calendarDates: studioBookingDates})
      }
      if (studioBookingDates == 0) {
        console.log(this.state.calendarDates.length)
      this.props.notification("Slots Unavailable", "This studio has no slots available, please check another studio");
      }

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  

  paginate = (number) => {
    this.setState({ studio: { ...this.state.studio, currentPage: number } });
  };

  handleDeleteBooking = async (StudioBookingID, updatedbookingDate, event) => {
    //event.preventDefault();
    // add call to AWS API Gateway delete product endpoint here
    NProgress.start();
    try {
      const studioBookingId = StudioBookingID;

      const bookingDate = updatedbookingDate;
      const todayDate = moment().format("YYYY-MM-DD");
      const dateRange = Moment.range(todayDate, bookingDate);
      const dayDiff = dateRange.diff("days");
      var group = this.props.auth.user.attributes['custom:cognitoGroup'];

      if (dayDiff <= 3) {
        this.props.notification("Unable to delete", "Your booking is within 3 days please contact booking@raptherapy.co.uk to cancel"); 
        
      } else {
        const res = await axios.delete(
          `${config.api.invokeUrl}/deletestudiobooking/${studioBookingId}?group=${group}`
        );
        const updatedStudioBookings = [...this.state.studioBookings].filter(
          (studioBooking) => studioBooking.StudioBookingID !== StudioBookingID
        );
        this.setState({ studioBookings: updatedStudioBookings });
        this.props.notification("Booking Delete", res.data);
      }
    } catch (res) {
      console.log("unable to delete" + res);
      this.props.notification("Unable to delete booking", res.response.data);
    }
    NProgress.done();
  };

  componentDidMount = () => {
    this.fetchStudioBooking();
    this.fetchBookings();
    this.fetchProducts();
    this.fetchStudent();
  };

  render() {
    const columns = [
      {
        Header: "Student",
        accessor: "studentname",
        sortable: false,
        show: true,
      },
      {
        Header: "Date",
        accessor: "bookingDate",
        filterable: false,
        show: true,
      },
      {
        Header: "Time",
        accessor: "bookingTime",
        sortable: false,
        filterable: false,
        show: true,
      },
      {
        Header: "Studio",
        accessor: "studiousername",
        sortable: false,
        filterable: false,
        show: true,
      },
      {
        Header: "StudioBookingID",
        accessor: "StudioBookingID",
        show: false,
      },
      {
        Header: "Delete Booking",
        sortable: false,
        filterable: false,
        Cell: (props) => {
          return (
            <button
              class="delete is-medium"
              onClick={() => {
                this.handleDeleteBooking(
                  props.original.StudioBookingID,
                  props.original.bookingDate
                );
              }}
            ></button>
          );
        },
        style: {
          textAlign: "center",
        },
        width: 120,
      },
    ];
    const indexOfLastPost =
      this.state.studio.currentPage * this.state.studio.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.studio.postsPerPage;
    const currentPosts = this.state.studios.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    var bookSessionClassName = this.state.tabState.bookSessionClass
      ? "is-active"
      : "";
    var currentSessionClassName = this.state.tabState.currentSessionsClass
      ? "is-active"
      : "";

    return (
      <Fragment>
        <div className="tabs is-centered is-boxed">
          <ul>
            <li className={bookSessionClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      bookSession: true,
                      bookSessionClass: true,
                    },
                  })
                }
              >
                Book a Session
              </a>
            </li>
            <li className={currentSessionClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      currentSessions: true,
                      currentSessionsClass: true,
                    },
                  })
                }
              >
                Current Bookings
              </a>
            </li>
          </ul>
        </div>
        {this.state.studio.loading && (
          <div class="section">
            <div class="container">
              <div class="columns is-centered">
                <BarLoader loading />
              </div>
            </div>
          </div>
        )}

      <div>
			  <Notification
			  />
		  </div>
        <div>
          <section class ="section">
          {this.state.tabState.bookSession && this.props.auth.isAuthenticated && (
            <div className="columns">
              <div class="column is-four-thirds" style={{height:"90vh",  top: "3.2rem"}}>
              <h3> Select a studio to view their availability:</h3>
              <br/>
                  <div class="col-lg-8 mx-auto">
                    <ul class="list-group shadow">
                      <div>
                        <Studios
                          loading={this.state.studio.loading}
                          studios={currentPosts}
                          username={this.props.auth.user.username}
                          updateBookings={this.filterBookings}
                        />
                      </div>
                      <div class="col-lg-7 mx-auto">
                        <Pagination
                          postPerPage={this.state.studio.postsPerPage}
                          totalPosts={this.state.studios.length}
                          loading={this.state.studio.loading}
                          paginate={this.paginate}
                        />
                      </div>
                    </ul>
                  </div>
              </div>
              {this.state.StudioBookingCalendar && this.props.auth.isAuthenticated && (
              <div className="column">
                {" "}
                <StudioBookingCalendar 
                isAdmin={true}
                bookingDates={this.state.calendarDates}
                students={this.state.studentList}
                handleSubmit={this.handleSubmit}
                />
              </div>
              )}
            </div>
          )}
        <div className="container py-5">
          {this.state.tabState.currentSessions &&
            this.props.auth.isAuthenticated && (
              <ReactTable
                columns={columns}
                data={this.state.studioBookings}
                filterable
                defaultPageSize={5}
                noDataText={"No bookings found"}
              ></ReactTable>
            )}
          {!this.props.auth.isAuthenticated && (
            <p>You are not authorised to access this page</p>
          )}
          </div>
          </section>
        </div>
      </Fragment>
    );
  }
}
