import React, { Component } from "react";
import ParentValidate from "./utility/SchoolFormParentValidation";
import StudentValidate from "./utility/SchoolFormStudentValidation";
import FormErrors from "./utility/SchoolFormErrors";
import axios from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";
import Notification from "./Notifications.js";
import NProgress from "nprogress";
import ReactTable from "react-table-6";
const config = require("../config.json");

export default class SchoolPortal extends Component {
  state = {
    parentfirstname: "",
    parentlastname: "",
    username: "",
    sex: "",
    medicalNeeds: "",
    medicalNeedsOthers: "",
    email: "",
    phoneNumber: "",
    token: "",
    borough: "",
    town: "",
    studentfirstname: "",
    studentlastname: "",
    studentparentemail: "",
    strugglesToEngage: false,
    diffFollowingInstructions: false,
    strugglesToRegulateEmotions: false,
    lowSelfEsteem: false,
    anxiety: false,
    angerIssues: false,
    SEMHotherChoice: false,
    SEMHother: "",
    dislexia: false,
    ADHD: false,
    processingDifficulties: false,
    autism: false,
    globalLearningDelay: false,
    speechAndLanguage: false,
    LearningNeedsOtherChoice: false,
    LearningNeedsOther: "",
    SEMHOtherClass: false,
    LearningNeedsOtherClass: false,
    day: "",
    month: "",
    studentList: [],
    year: "",
    tabState: {
      parentRegistry: true,
      parentRegistryClass: true,
      studentRegistry: false,
      studentRegistryClass: false,
      studentList: false,
      studentListClass: false,
    },
    parentErrors: {
      cognito: null,
      blankfield: false,
      usernamespaces: false,
    },
    studentErrors: {
      cognito: null,
      blankfield: false,
      semhother: false,
      learningneedsother: false,
      medicalneedsothers: false,
    },
  };

  clearParentErrorState = () => {
    this.setState({
      parentErrors: {
        cognito: null,
        blankfield: false,
        usernamespaces: false,
      },
    });
  };

  clearStudentErrorState = () => {
    this.setState({
      studentErrors: {
        cognito: null,
        blankfield: false,
        semhother: false,
        learningneedsother: false,
        medicalneedsothers: false,
      },
    });
  };

  fetchStudent = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      var username = this.props.auth.user.username;
      var group = this.props.auth.user.attributes["custom:cognitoGroup"];
      username.toLowerCase();
      const res = await axios.get(
        `${config.api.invokeUrl}/students/${username}?group=${group}`
      );

      this.setState({
        studentList: res.data.Items,
      });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  studentHandleSubmit = async (event) => {
    event.preventDefault();
    this.clearStudentErrorState();
    const error = StudentValidate(event, this.state);
    if (error) {
      this.setState({
        studentErrors: { ...this.state.errors, ...error },
      });
    } else {
      // add call to AWS API Gateway update product endpoint here
      NProgress.start();
      try {
        const dateOfBirth =
          this.state.year + "-" + this.state.month + "-" + this.state.day;
        const params = {
          studentfirstname: this.state.studentfirstname,
          studentlastname: this.state.studentlastname,
          parentemail: this.state.studentparentemail,
          id: this.state.studentfirstname + moment().format("x"),
          strugglesToEngage: this.state.strugglesToEngage,
          diffFollowingInstructions: this.state.diffFollowingInstructions,
          strugglesToRegulateEmotions: this.state.strugglesToRegulateEmotions,
          lowSelfEsteem: this.state.lowSelfEsteem,
          anxiety: this.state.anxiety,
          angerIssues: this.state.angerIssues,
          SEMHother: this.state.SEMHother,
          dislexia: this.state.dislexia,
          ADHD: this.state.ADHD,
          processingDifficulties: this.state.processingDifficulties,
          autism: this.state.autism,
          globalLearningDelay: this.state.globalLearningDelay,
          speechAndLanguage: this.state.speechAndLanguage,
          LearningNeedsOther: this.state.LearningNeedsOther,
          dob: dateOfBirth,
          sex: this.state.sex,
          medicalNeeds: this.state.medicalNeeds,
          medicalNeedsOthers: this.state.medicalNeedsOthers,
          schoolUsername: this.props.auth.user.username,
        };

        const headers = {
          headers: { Authorization: this.state.token },
        };

        const res = await axios.post(
          `${config.api.invokeUrl}/students`,
          params,
          headers
        );
        this.props.notification("Student registered!", res.data);
      } catch (res) {
        if (res == "Error: Network Error") {
          this.props.notification(
            "Access Denied",
            "You do not have access to create a student. Please contact info@raptherapy.co.uk"
          );
        } else {
          console.log("unable to register student" + res);
          this.props.notification(
            "Unable to register student",
            res.response.data
          );
        }
      }
      NProgress.done();
    }
  };

  getOptions(start, end) {
    const options = [];

    for (let i = start; i <= end; i++) {
      options.push(<option key={i}>{i}</option>);
    }

    return options;
  }

  AuthCreds = async (event) => {
    if (this.props.auth.user != null) {
      const { user } = this.props.auth.user;

      await Auth.currentSession(user).then((res) => {
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        let idToken = res.getIdToken();
        this.setState({ token: idToken.jwtToken });
      });
    }
  };

  parentHandleSubmit = async (event) => {
    event.preventDefault();
    this.clearParentErrorState();
    const error = ParentValidate(event, this.state);
    if (error) {
      this.setState({
        parentErrors: { ...this.state.errors, ...error },
      });
    } else {
      // add call to AWS API Gateway update product endpoint here
      NProgress.start();
      var username = this.state.username;
      var usernameReformatted = username.toLowerCase();
      try {
        const params = {
          parentfirstname: this.state.parentfirstname,
          parentlastname: this.state.parentlastname,
          username: usernameReformatted,
          email: this.state.email,
          group: "Parent",
          phoneNumber: this.state.phoneNumber,
          town: this.state.town,
          borough: this.state.borough,
          id: this.state.username + moment().format("x"),
          schoolUsername: this.props.auth.user.username,
        };

        const headers = {
          headers: { Authorization: this.state.token },
        };

        const res = await axios.post(
          `${config.api.invokeUrl}/parents`,
          params,
          headers
        );
        this.props.notification("Parent registered!", res.data);
      } catch (res) {
        if (res == "Error: Network Error") {
          this.props.notification(
            "Access Denied",
            "You do not have access to create a parent. Please contact info@raptherapy.co.uk"
          );
        } else {
          console.log("unable to register user" + res);
          this.props.notification(
            "Unable to register parent",
            res.response.data
          );
        }
      }
      NProgress.done();
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  onInputClick = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  componentDidMount = () => {
    this.AuthCreds();
    this.fetchStudent();
  };

  render() {
    var parentRegistryClassName = this.state.tabState.parentRegistryClass
      ? "is-active"
      : "";
    var studentRegistryClassName = this.state.tabState.studentRegistryClass
      ? "is-active"
      : "";
    var studentListClassName = this.state.tabState.studentListClass
      ? "is-active"
      : "";
    var SEMHOtherClassName = this.state.SEMHOtherClass ? "" : "none";
    var LearningNeedsOtherClassName = this.state.LearningNeedsOtherClass
      ? ""
      : "none";

    const columns = [
      {
        Header: "Student First Name",
        accessor: "studentfirstname",
        sortable: false,
        filterable: true,
        show: true,
      },
      {
        Header: "Student Last Name",
        accessor: "studentlastname",
        filterable: true,
        show: true,
      },
      {
        Header: "Parent First Name",
        accessor: "ParentFirstName",
        sortable: false,
        filterable: true,
        show: true,
      },
      {
        Header: "Parent Last Name",
        accessor: "ParentLastName",
        sortable: false,
        filterable: true,
        show: true,
      },
      {
        Header: "Parent Email",
        accessor: "ParentEmail",
        filterable: true,
        show: true,
      },
      {
        Header: "Parent Phone Number",
        accessor: "ParentPhoneNumber",
        show: true,
      },
    ];

    const day = this.state.day;
    const month = this.state.month;
    const year = this.state.year;
    const thisYear = new Date().getFullYear();

    return (
      <section className="section auth">
        <div className="tabs is-centered is-boxed">
          <ul>
            <li className={parentRegistryClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      parentRegistry: true,
                      parentRegistryClass: true,
                    },
                  })
                }
              >
                Parent Registry
              </a>
            </li>
            <li className={studentRegistryClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      studentRegistry: true,
                      studentRegistryClass: true,
                    },
                  })
                }
              >
                Student Registry
              </a>
            </li>
            <li className={studentListClassName}>
              <a
                onClick={() =>
                  this.setState({
                    tabState: {
                      studentList: true,
                      studentListClass: true,
                    },
                  })
                }
              >
                Student List
              </a>
            </li>
          </ul>
        </div>

        <div>
          <Notification />
        </div>

        <div className="columns is-mobile is-centered">
          {this.state.tabState.parentRegistry &&
            this.props.auth.isAuthenticated && (
              <React.Fragment>
                <form onSubmit={(event) => this.parentHandleSubmit(event)}>
                  <FormErrors formerrors={this.state.parentErrors} />
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Name</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="parentfirstname"
                            aria-describedby="userNameHelp"
                            placeholder="First Name of Parent"
                            value={this.state.parentfirstname}
                            onChange={this.onInputChange}
                            required
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>
                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="parentlastname"
                            aria-describedby="userNameHelp"
                            placeholder="Last Name of Parent"
                            value={this.state.parentlastname}
                            onChange={this.onInputChange}
                            required
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p className="control is-expanded has-icons-left has-icons-right">
                            <input
                              className="input"
                              type="text"
                              id="username"
                              aria-describedby="userNameHelp"
                              placeholder="Username For Parent"
                              value={this.state.username}
                              onChange={this.onInputChange}
                            />
                            <span class="icon is-small is-left">
                              <i class="fas fa-user"></i>
                            </span>
                            <span class="icon is-small is-right">
                              <i class="fas fa-check"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Location</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="borough"
                            aria-describedby="userNameHelp"
                            placeholder="Borough"
                            value={this.state.borough}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="town"
                            aria-describedby="userNameHelp"
                            placeholder="County"
                            value={this.state.town}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-home"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Phone Number</label>
                    </div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                              class="input"
                              type="tel"
                              id="phoneNumber"
                              placeholder="Parent Phone Number"
                              value={this.state.phoneNumber}
                              onChange={this.onInputChange}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Email</label>
                    </div>
                    <div class="field-body">
                      <div className="field">
                        <div class="control">
                          <p className="control has-icons-left has-icons-right">
                            <input
                              className="input"
                              type="email"
                              id="email"
                              aria-describedby="emailHelp"
                              placeholder="Enter Parent E-mail"
                              value={this.state.email}
                              onChange={this.onInputChange}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <button className="button is-dark">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}
          {this.state.tabState.studentRegistry &&
            this.props.auth.isAuthenticated && (
              <React.Fragment>
                <form onSubmit={(event) => this.studentHandleSubmit(event)}>
                  <FormErrors formerrors={this.state.studentErrors} />
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Name</label>
                    </div>

                    <div class="field-body">
                      <div class="field">
                        <p class="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            id="studentfirstname"
                            aria-describedby="userNameHelp"
                            placeholder="Student First Name"
                            value={this.state.studentfirstname}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                        </p>
                      </div>
                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className="input"
                            type="text"
                            id="studentlastname"
                            aria-describedby="userNameHelp"
                            placeholder="Student Last Name"
                            value={this.state.studentlastname}
                            onChange={this.onInputChange}
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Details</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="select">
                          <select
                            name="sex"
                            id="sex"
                            value={this.state.sex}
                            onChange={this.onInputChange}
                            required
                          >
                            <option selected disabled value="">
                              Choose a sex
                            </option>
                            <option value="male">male</option>
                            <option value="female">female</option>
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <div class="select">
                          <select
                            name="medicalNeeds"
                            id="medicalNeeds"
                            value={this.state.medicalNeeds}
                            onChange={this.onInputChange}
                            required
                          >
                            <option selected disabled value="">
                              Does student have medical needs?
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.medicalNeeds == "Yes" && (
                    <div class="field is-horizontal">
                      <div class="field-label is-normal"></div>
                      <div class="field-body">
                        <div class="field">
                          <textarea
                            id="medicalNeedsOthers"
                            value={this.state.medicalNeedsOthers}
                            onChange={this.onInputChange}
                            class="textarea is-small"
                            placeholder="Please enter other medical needs"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Date of Birth</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="select">
                          <select
                            id="day"
                            onChange={this.onInputChange}
                            value={day}
                            name="day"
                            required
                          >
                            <option selected disabled value="">
                              Day
                            </option>
                            <option />
                            {this.getOptions(1, 31)}
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <div class="select">
                          <select
                            placeholder="Month"
                            id="month"
                            onChange={this.onInputChange}
                            value={month}
                            name="month"
                            required
                          >
                            <option selected disabled value="">
                              Month
                            </option>
                            <option />
                            {this.getOptions(1, 12)}
                          </select>
                        </div>
                      </div>

                      <div className="field">
                        <div class="select">
                          <select
                            placeholder="Year"
                            id="year"
                            onChange={this.onInputChange}
                            value={year}
                            name="year"
                            required
                          >
                            <option selected disabled value="">
                              Year
                            </option>
                            <option />
                            {this.getOptions(thisYear - 20, thisYear)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Parent email</label>
                    </div>
                    <div class="field-body">
                      <div className="field">
                        <div class="control">
                          <p className="control has-icons-left has-icons-right">
                            <input
                              className="input"
                              type="text"
                              id="studentparentemail"
                              aria-describedby="userNameHelp"
                              placeholder="Enter Parent E-mail"
                              value={this.state.studentparentemail}
                              onChange={this.onInputChange}
                              required
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">SEMH Needs</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="strugglesToEngage"
                                value={this.state.strugglesToEngage}
                                onClick={this.onInputClick}
                              />
                              <span>Struggles to Engage</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="diffFollowingInstructions"
                                value={this.state.diffFollowingInstructions}
                                onClick={this.onInputClick}
                              />
                              <span>Difficulty Following Instructions</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="strugglesToRegulateEmotions"
                                value={this.state.strugglesToRegulateEmotions}
                                onClick={this.onInputClick}
                              />
                              <span>Struggles to Regulate Emotions</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="lowSelfEsteem"
                                value={this.state.lowSelfEsteem}
                                onClick={this.onInputClick}
                              />
                              <span>Low Self-esteem</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="anxiety"
                                value={this.state.anxiety}
                                onClick={this.onInputClick}
                              />
                              <span>Anxiety</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="angerIssues"
                                value={this.state.angerIssues}
                                onClick={this.onInputClick}
                              />
                              <span>Anger Issues</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="SEMHotherChoice"
                                value={this.state.SEMHotherChoice}
                                onClick={() =>
                                  this.setState({
                                    SEMHOtherClass: !this.state.SEMHOtherClass,
                                    SEMHotherChoice: !this.state
                                      .SEMHotherChoice,
                                  })
                                }
                              />
                              <span>Other</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="field is-horizontal"
                    style={{ display: SEMHOtherClassName }}
                  >
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <textarea
                          id="SEMHother"
                          value={this.state.SEMHother}
                          onChange={this.onInputChange}
                          class="textarea is-small"
                          placeholder="Please enter comma seperated"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Learning Needs</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>Dyslexia</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>ADHD</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>Processing Difficulties</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>Autism</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>Global Learning Delay</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input type="checkbox" value="1" />
                              <span>Speech and Language</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="b-checkbox is-warning is-inline">
                          <div id="ck-button">
                            <label>
                              <input
                                type="checkbox"
                                id="LearningNeedsOtherChoice"
                                value={this.state.LearningNeedsOtherChoice}
                                onChange={this.onInputChange}
                                onClick={() =>
                                  this.setState({
                                    LearningNeedsOtherClass: !this.state
                                      .LearningNeedsOtherClass,
                                    LearningNeedsOtherChoice: !this.state
                                      .LearningNeedsOtherChoice,
                                  })
                                }
                              />
                              <span>Other</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="field is-horizontal"
                    style={{ display: LearningNeedsOtherClassName }}
                  >
                    <div class="field-label is-normal"></div>
                    <div class="field-body">
                      <div class="field">
                        <textarea
                          id="LearningNeedsOther"
                          value={this.state.LearningNeedsOther}
                          onChange={this.onInputChange}
                          class="textarea is-small"
                          placeholder="Please enter comma seperated"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <button className="button is-dark">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}
        </div>
        <div className="container py-5">
          {this.state.tabState.studentList &&
            this.props.auth.isAuthenticated && (
              <ReactTable
                columns={columns}
                data={this.state.studentList}
                filterable
                defaultPageSize={5}
                noDataText={"No registered students"}
              ></ReactTable>
            )}
          {!this.props.auth.isAuthenticated && (
            <p>You are not authorised to access this page</p>
          )}
        </div>
        {!this.props.auth.isAuthenticated && (
          <p>You are not authorised to access this page</p>
        )}
      </section>
    );
  }
}
